<template lang="pug">
  .competitor-data
    //- competitor's data
    .competitor-data-row(
      :class="{ opened: pricePlanToggler.state, disabled: isPricePlansEmpty }"
    )
      .shop(
        :class="{ disabled: isPricePlansEmpty }"
        :title="competitor.shop_name"
        @click="pricePlanToggler.toggle"
      ) {{ competitor.shop_name }}
      .price-wrapper(
        v-for="date in dateRange"
        :key="String(date)"
      )
        .price {{ renderPrice($n(competitorPrice(competitor.prices, date))) }}
    //- competitor's price plans data
    template(v-if="pricePlanToggler.state && !isPricePlansEmpty")
      .competitor-data-price-plan(
        v-for="pricePlan in pricePlans"
        :key="pricePlan.id"
      )
        .price-plan-name(:title="pricePlan.name") {{ pricePlan.name }}
        .price-wrapper(
          v-for="date in dateRange"
          :key="`${pricePlan.id}-${date}`"
        )
          .price {{ renderPrice($n(fetchValue(pricePlan, date))) }}
</template>

<script>
  import { toggleize } from "@/helpers/toggler"
  import { get, isEmpty } from "lodash-es"

  export default {
    props: {
      competitor: Object,
      rentalPeriod: String,
      dateRange: Array,
      groupedFormattedDates: Object
    },

    beforeMount() {
      this.pricePlanToggler = toggleize(`price-plan-toggler-${this.competitor.id}`, false)
    },

    computed: {
      pricePlans({ competitor, rentalPeriod }) {
        return competitor.price_plans[rentalPeriod]
      },

      isPricePlansEmpty() {
        return isEmpty(this.pricePlans)
      }
    },

    methods: {
      isEmpty,

      renderPrice(price) {
        return price === "NaN" ? "——" : price
      },

      competitorPrice(prices, date) {
        // prices: {
        //   "1day" -> (this.rentalPeriod): {
        //     "2021/09/13" -> (this.fullDate(date)): 1900,
        //     "2021/09/14": 2900,
        //     "2021/09/15": 2600,
        //     ...
        //   }
        // }
        return get(prices, [this.rentalPeriod, this.fullDate(date)], "-")
      },

      fullDate(date) {
        return this.groupedFormattedDates[date].fullDate
      },

      fetchValue(pricePlan, date) {
        return pricePlan.prices[this.fullDate(date)]
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/common.sass"
  @import "@/assets/styles/mixins/price-calendar.sass"

  $row-line-height: 50px

  .disabled
    cursor: not-allowed !important
    opacity: 0.6

  .competitor-data
    &-row
      +table-row
      border-bottom: 1px solid $border-element-color
      line-height: $row-line-height

      &:hover
        opacity: 0.7

      &:hover,
      &.opened
        box-shadow: 0 0 3px 2px $border-element-color

    &-row
      &.disabled
        &:hover
          box-shadow: none
          opacity: 0.6

      .shop
        +truncated
        color: $default-purple
        cursor: pointer
        user-select: none

    &-price-plan
      +table-row
      background-color: $default-gray-light
      line-height: $row-line-height
      color: $default-gray

      .price-plan-name
        +truncated
        cursor: default

    .no-data
      background-color: $default-gray-light
      text-align: center
      line-height: $row-line-height
</style>
