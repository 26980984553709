import { render, staticRenderFns } from "./CompetitorData.vue?vue&type=template&id=36ff1c54&scoped=true&lang=pug&"
import script from "./CompetitorData.vue?vue&type=script&lang=js&"
export * from "./CompetitorData.vue?vue&type=script&lang=js&"
import style0 from "./CompetitorData.vue?vue&type=style&index=0&id=36ff1c54&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36ff1c54",
  null
  
)

export default component.exports